import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, } from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { ChipRowInput, ChipRowOutput, isExpression, MatColorDefinitionModel, WidgetAction } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ButtonComponent } from '@app/widgets/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { ThemePalette } from '@angular/material/core';


@Register('ChipRow')
@Component({
  selector: 'tb-chip-row',
  templateUrl: './chip-row.component.html',
  styleUrls: ['./chip-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AsyncPipe, ParsePipe, MatLegacyChipsModule, NgForOf, DynamicWidgetDirective, NgClass, ButtonComponent, MatIconModule],
})
export class ChipRowComponent
  extends BaseWidgetComponent<ChipRowInput, ChipRowOutput>
  implements OnInit, OnDestroy {

  color: ThemePalette | null;
  backgroundColor: ThemePalette | null;

  protected setColorActive(
    type: 'foreground' | 'background',
    color: MatColorDefinitionModel
  ) {
    if (type === 'foreground') {
      this.color = color.palette || null;
    } else {
      this.backgroundColor = color.palette || null;
    }
    this._cd.markForCheck();
  }

  async handleRemoved() {
    if (this.input.onRemove) {
      let removeAction: WidgetAction | WidgetAction[] | undefined;
      if (isExpression(this.input.onRemove)) {
        removeAction = await this._parser.parseAsPromise(this.input.onRemove);
      } else {
        removeAction = this.input.onRemove;
      }
      if (removeAction) {
        this.dispatchActions(removeAction).subscribe();
      }

    }
  }

  protected readonly isExpression = isExpression;
}
