import type { IfExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';
import { Func } from '@app/expressions/parser';

export const ifResolver = createNamedExpressionResolver<IfExpression>(
  'if',
  (parser, options, onDataChange, [condition, then, otherwise]) => {
    let innerUnsubscribe: void | Func<[], void>;
    let state: 'truthy' | 'falsy' | undefined;
    const outerUnsubscribe = parser.parseWithCallback(
      condition,
      {
        error: onDataChange.error,
        next: resolvedCondition => {
          if (resolvedCondition) {
            if (state === undefined || state === 'falsy') {
              state = 'truthy';
              if (innerUnsubscribe) {
                innerUnsubscribe();
              }
              innerUnsubscribe = parser.parseWithCallback(
                then,
                onDataChange,
                options
              );
            }
          } else {
            if (state === undefined || state === 'truthy') {
              state = 'falsy';
              if (innerUnsubscribe) {
                innerUnsubscribe();
              }
              innerUnsubscribe = parser.parseWithCallback(
                otherwise,
                onDataChange,
                options
              );
            }
          }
        },
      },
      options
    );
    return () => {
      if (outerUnsubscribe) {
        outerUnsubscribe();
      }
      if (innerUnsubscribe) {
        innerUnsubscribe();
      }
    };
  }
);
