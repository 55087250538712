import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  LegacyPageEvent as PageEvent,
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule,
} from '@angular/material/legacy-paginator';
import { Register } from '@app/utils/type-registry';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { WidgetsState } from '@app/state/widgets/widgets.state';
import {
  LocalActionModel,
  PaginatorInput,
  PaginatorOutput,
} from '@trackback/widgets';
import { ServerSidePaginator } from '@trackback/widgets/build/main/widgets/definitions/paginator';
import { BaseWidgetComponent } from '../../widgets/base-widget.component';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { AsyncPipe, NgIf } from '@angular/common';
import { LoadingStateCounter } from '@app/utils/loading-state-counter.class';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'tb-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    DynamicWidgetDirective,
    MatLegacyPaginatorModule,
    AsyncPipe,
    MatLegacyProgressSpinnerModule,
  ],
})
@Register('Paginator')
export class PaginatorComponent
  extends BaseWidgetComponent<PaginatorInput, PaginatorOutput>
  implements OnInit, AfterViewInit
{
  // Override the HostBinding from BaseWidgetComponent so we can add margin to the paginator
  @HostBinding('style.marginTop')
  noneTop = 0;
  @HostBinding('style.marginRight')
  noneRight = 0;
  @HostBinding('style.marginBottom')
  noneBottom = 0;
  @HostBinding('style.marginLeft')
  noneLeft = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoadingResults = false;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  length: number;
  paginationType = 'client';
  data = new BehaviorSubject<unknown[]>([]);
  public filteredData = new BehaviorSubject<unknown[]>([]);
  refresh$ = new BehaviorSubject(null);
  loadingStateCounter = new LoadingStateCounter();

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.paginationType = this.input.paginationType || 'client';

    if (this.input.pageSize) {
      this.parse(this.input.pageSize)
        .pipe(take(1))
        .subscribe(pageSize => {
          this.pageSize = Number(pageSize);
          this.paginator.pageSize = this.pageSize;
          this.refreshData();
        });
    } else {
      this.refreshData();
    }

    if (this.input.pageSizeOptions) {
      this.pageSizeOptions = this.input.pageSizeOptions;
    }

    if (this.input.data) {
      this.setLoading(true);

      this.refresh$
        .pipe(
          switchMap(() =>
            this.parse(this.input.data, {
              loadingStateCounter: this.loadingStateCounter,
            })
          ),
          takeUntil(this.destroyed$)
        )
        .subscribe(
          data => {
            if (this.paginationType === 'server') {
              const parsedData =
                data.data != null
                  ? (data as ServerSidePaginator)
                  : { data, count: data.length };

              this.data.next(parsedData.data);
              this.length = Number(parsedData.count);
            } else {
              const { currentPage, pageSize } = this._store.selectSnapshot(
                WidgetsState.getWidgetOutput(this.id)
              ) || { currentPage: 0, pageSize: 10 };

              this.paginator.pageIndex = currentPage as number;
              this.paginator.pageSize = pageSize as number;
              this.pageIndex = currentPage as number;
              this.pageSize = pageSize as number;
              this.data.next(data);
            }

            this.refreshData();
            this.setLoading(false);
          },
          err => {
            this.setLoading(false);
            return throwError(err);
          }
        );
    }

    if (this.input.itemsPerPageLabel) {
      this.parse(this.input.itemsPerPageLabel)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(parsedExpression => {
          this.paginator._intl.itemsPerPageLabel = `${parsedExpression}:`;
          this.paginator._intl.changes.next();
        });
    }
  }

  ngAfterViewInit() {
    if (this.paginationType === 'client') {
      this.data.pipe(takeUntil(this.destroyed$)).subscribe(rows => {
        this.length = rows.length;
        this.refreshData();
      });
    }
  }

  refreshData() {
    const pageEvent = this.getPageEvent(this.paginator);
    this.data.pipe(this.paginateRows(pageEvent), take(1)).subscribe(data => {
      this.filteredData.next(data);
    });
  }

  bindPaginator(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.refreshData();
  }

  getPageEvent(paginator: MatPaginator): Observable<PageEvent> {
    return of(<PageEvent>{
      pageIndex: paginator.pageIndex,
      pageSize: paginator.pageSize,
      length: paginator.length,
    });
  }

  setLoading(loader: boolean) {
    this.isLoadingResults = loader;
    this._cd.detectChanges();
  }

  /**
   * A local action to trigger the pagination to show the loading widget.
   * Can be used to show the loading bar if and when something external
   * updates the data and the component is not notified about the change
   * until its compete.
   *
   * @remarks
   * If the payload is not provided, the value is assumed to be true.
   *
   * @param {LocalActionModel} action The action with the boolean payload
   */
  handleSetLoadingAction(action: LocalActionModel): Observable<null> {
    const loadingState =
      action.payload == null ? true : (action.payload as boolean);
    this.setLoading(loadingState);
    return of(null);
  }

  handleRefreshAction() {
    this.refresh$.next(null);
    return of(null);
  }

  paginateRows<U>(
    page$: Observable<PageEvent>
  ): (obs$: Observable<U[]>) => Observable<U[]> {
    return (rows$: Observable<U[]>) =>
      combineLatest([rows$, page$]).pipe(
        map(([rows, page]) => {
          this.updateOutput({
            currentPage: page.pageIndex,
            pageSize: page.pageSize,
            rowCount: rows.length,
          });

          if (this.paginationType === 'client') {
            const startIndex = this.pageIndex * page.pageSize,
              copy = rows.slice();

            if (startIndex >= rows.length) {
              return copy.splice(0, page.pageSize);
            }

            return copy.splice(startIndex, page.pageSize);
          }

          if (rows.length === 0) {
            this.pageIndex = 0;
            this.updateOutput({ currentPage: 0 });
          }

          return rows;
        })
      );
  }
}
