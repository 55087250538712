import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  DEFAULT_CLIENT_ERROR_WIDGET,
  DEFAULT_NOT_FOUND_WIDGET,
  DEFAULT_SERVER_ERROR_WIDGET,
  DEFAULT_UNAUTHENTICATED_ERROR_WIDGET,
  DEFAULT_UNAUTHORISED_ERROR_WIDGET,
} from '@app/models/default-widgets';
import { Store } from '@ngxs/store';
import { WidgetInput, WidgetInputModel } from '@trackback/widgets';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import {
  DEFAULT_FALLBACK_LANGUAGE_CODE,
  TranslationState,
} from '@app/state/translations/translation.state';
import { TranslationMap } from '@app/models/translation-map.model';
import { LoadTranslationSuccess } from '@app/state/translations/translation.actions';
import { APP_CONFIG, AppConfigModel } from '@app/models/app-config.model';
import { LayoutState } from '@app/state/layout/layout.state';
import { SetActiveClientId } from '@app/state/layout/layout.actions';

@Injectable({ providedIn: 'root' })
export class DefaultConfigResolverGuard {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfigModel,
    private readonly http: HttpClient,
    private readonly store: Store
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<WidgetInput> {
    let queryParams: string;

    function parseToJson(routeFragment: string) {
      const split1 = routeFragment.split('?'),
        split2 = split1[1].split('&'),
        ret = {};
      split2.forEach(j => {
        const [key, value] = j.split('=');
        ret[key] = value;
      });
      return ret;
    }

    if (route.fragment && route.fragment.includes('?')) {
      queryParams = JSON.stringify(parseToJson(route.fragment));
    } else if (route.queryParams) {
      queryParams = JSON.stringify(route.queryParams);
    }

    const languageCode =
        this.store.selectSnapshot(TranslationState.getLanguageCode) ||
        navigator.language ||
        DEFAULT_FALLBACK_LANGUAGE_CODE,
      activeClientId =
        this.store.selectSnapshot(LayoutState.getActiveClientId) || null,
      urlSplit = state.url.split('?')[0].replace('#!/', '/');
    return this.http
      .get<
        WidgetInputModel | [WidgetInputModel, TranslationMap, number]
      >(`${this.config.API_PROTOCOL}://${this.config.API_URL}/view-config` + `${urlSplit}?client=${activeClientId}&language=${languageCode}&queryParams=${queryParams}`)
      .pipe(
        switchMap(result => {
          if (Array.isArray(result)) {
            const [config, translations, clientId] = result;

            return this.store
              .dispatch([
                new SetActiveClientId(clientId),
                new LoadTranslationSuccess(languageCode, translations),
              ])
              .pipe(mapTo(config));
          } else {
            return of(result);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let result: WidgetInput;
          if (error.error instanceof ErrorEvent) {
            result = DEFAULT_CLIENT_ERROR_WIDGET;
          } else {
            if (error.status === 401) {
              result = DEFAULT_UNAUTHENTICATED_ERROR_WIDGET;
            } else if (error.status === 403) {
              result = DEFAULT_UNAUTHORISED_ERROR_WIDGET;
            } else if (error.status === 404) {
              result = DEFAULT_NOT_FOUND_WIDGET;
            } else if (error.status >= 500) {
              result = DEFAULT_SERVER_ERROR_WIDGET;
            } else {
              result = DEFAULT_CLIENT_ERROR_WIDGET;
            }
          }
          return of(result);
        })
      );
  }
}
