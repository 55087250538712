import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngxs/store';
import { distinctUntilChanged } from 'rxjs/operators';
import type { Locale } from 'date-fns';
import {
  DEFAULT_FALLBACK_LANGUAGE_CODE,
  TranslationState,
} from '@app/state/translations/translation.state';
import dateFnsLocales from '@app/models/date-fns-locales';

export const appInitialiserFactory =
  (store: Store, dateAdapter: DateAdapter<Date, Locale>) => async () => {
    try {
      // Update uis date locale on each language change
      store
        .select(TranslationState.getLanguageCode)
        .pipe(distinctUntilChanged())
        .subscribe(newLanguageCode => {
          dateAdapter.setLocale(
            dateFnsLocales[newLanguageCode] ||
              dateFnsLocales[DEFAULT_FALLBACK_LANGUAGE_CODE]
          );
        });
    } catch (error) {
      console.error('Error during application setup', error);
    }
  };
