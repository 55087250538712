<ng-container *ngFor="let headerRow of headerRows; let level = index">
  <span
    *ngFor="let head of headerRow; trackBy: trackByHeadNode"
    class="mat-header-cell tree-table-header"
    role="columnheader"
    [ngClass]="[
      head.shade === true ? 'shade' : '',
      'rowspan-' + head.rowspan,
      'colspan-' + head.colspan,
      'level-' + level,
      head.rightmostCellInHeader === true ? 'rightmost' : '',
      head.leftmostCellInHeader === true ? 'leftmost' : '',
      head.firstColumnInHeader === true ? 'firstColumn' : '',
      head.leftmostOfSecondColumn === true ? 'leftmostOfSecondColumn' : '',
    ]"
  >
    <ng-container *ngIf="isRawString(head.widget); else expressionOrWidget">
      {{ head.widget }}
    </ng-container>
    <ng-template #expressionOrWidget>
      <ng-container *ngIf="isExpression(head.widget); else widget">
        {{ head.widget | parse: context | async }}
      </ng-container>
      <ng-template #widget>
        <ng-container
          [dynamicWidget]="head.widget"
          [context]="context"
        ></ng-container>
      </ng-template>
    </ng-template>
  </span>
</ng-container>
<div *ngFor="let node of nodes$ | async" role="row">
  <ng-container *ngFor="let dataColumn of activeColumn; let first = first">
    <ng-template #baseCell>
      <span
        class="tb-tree-cell mat-cell"
        role="gridcell"
        [ngClass]="dataColumn.shade === true ? 'shade' : ''"
        [style.minWidth]="
          dataColumn.width > 0 ? dataColumn.width + 'px' : 'initial'
        "
      >
        <ng-container
          *ngIf="
            mergeContexts(
              context,
              wrapContext(node, input?.rowDataAlias || 'node')
            ) as mergedContext
          "
        >
          <ng-template #text>{{
            dataColumn.widget | parse: mergedContext | async
          }}</ng-template>
          <ng-container *ngIf="!isExpression(dataColumn.widget); else text">
            <ng-container
              [dynamicWidget]="
                dataColumn.widget
                  | parse
                    : mergedContext
                    : { maxParseDepth: 1, keyWhitelist: ['id'] }
                  | async
              "
              [context]="mergedContext"
            ></ng-container>
          </ng-container>
        </ng-container>
      </span>
    </ng-template>

    <ng-container *ngIf="first; else baseCell">
      <ng-template #leafButton>
        <span
          class="tb-tree-cell mat-cell"
          role="gridcell"
          [style.paddingLeft.px]="node.level * 8"
          [style.minWidth]="
            dataColumn.width > 0 ? dataColumn.width + 'px' : 'max-content'
          "
          [ngClass]="[
            dataColumn.shade === true ? 'shade' : '',
            input.firstColumnSticky === true ? 'sticky' : '',
          ]"
        >
          <ng-container
            *ngIf="
              mergeContexts(
                context,
                wrapContext(node, input?.rowDataAlias || 'node')
              ) as mergedContext
            "
          >
            <ng-template #text>{{
              dataColumn.widget | parse: mergedContext | async
            }}</ng-template>
            <ng-container *ngIf="!isExpression(dataColumn.widget); else text">
              <ng-container
                [dynamicWidget]="
                  dataColumn.widget
                    | parse
                      : mergedContext
                      : { maxParseDepth: 1, keyWhitelist: ['id'] }
                    | async
                "
                [context]="mergedContext"
              ></ng-container>
            </ng-container>
          </ng-container>
        </span>
      </ng-template>
      <ng-container *ngIf="node.expandable; else leafButton">
        <span
          class="tb-tree-cell mat-cell expandable"
          role="gridcell"
          [style.paddingLeft.px]="node.level * 8"
          [style.minWidth]="
            dataColumn.width > 0 ? dataColumn.width + 'px' : 'max-content'
          "
          [ngClass]="dataColumn.shade === true ? 'shade' : ''"
          (dblclick)="treeControl.toggle(node)"
        >
          <button mat-icon-button (click)="treeControl.toggle(node)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
              }}
            </mat-icon>
          </button>
          <ng-container
            *ngIf="
              mergeContexts(
                context,
                wrapContext(node, input?.rowDataAlias || 'node')
              ) as mergedContext
            "
          >
            <ng-template #text>{{
              dataColumn.widget | parse: mergedContext | async
            }}</ng-template>
            <ng-container *ngIf="!isExpression(dataColumn.widget); else text">
              <ng-container
                [dynamicWidget]="
                  dataColumn.widget
                    | parse
                      : mergedContext
                      : { maxParseDepth: 1, keyWhitelist: ['id'] }
                    | async
                "
                [context]="mergedContext"
              ></ng-container>
            </ng-container>
          </ng-container>
        </span>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
