import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation, } from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { ChipGridInput, ChipGridOutput, ChipRowInput, ChipRowOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ButtonComponent } from '@app/widgets/button/button.component';
import { MatIconModule } from '@angular/material/icon';


@Register('ChipGrid')
@Component({
  selector: 'tb-chip-grid',
  templateUrl: './chip-grid.component.html',
  styleUrls: ['./chip-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AsyncPipe, ParsePipe, MatLegacyChipsModule, NgForOf, DynamicWidgetDirective, NgClass, ButtonComponent, MatIconModule],
})
export class ChipGridComponent
  extends BaseWidgetComponent<ChipGridInput, ChipGridOutput>
  implements OnInit, OnDestroy {
}
