<mat-expansion-panel
  [expanded]="input.expanded | parse: context | async"
  [disabled]="input.disabled | parse: context | async"
>
  <mat-expansion-panel-header
    [collapsedHeight]="input.panelHeaderHeight | parse: context | async"
    [expandedHeight]="input.panelContentHeight | parse: context | async"
  >
    <mat-panel-title>
      <ng-container
        [dynamicWidget]="input.title"
        [context]="context"
      ></ng-container>
    </mat-panel-title>
    <mat-panel-description *ngIf="input.description">
      <ng-container
        [dynamicWidget]="input.description"
        [context]="context"
      ></ng-container>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div
      [ngStyle]="{
        backgroundColor: input.panelBackgroudColor | parse: context | async,
      }"
    >
      <ng-container
        *ngFor="let widget of input.widgets"
        [dynamicWidget]="widget"
        [context]="context"
      ></ng-container>
    </div>
  </ng-template>
  <mat-action-row *ngIf="input.actions">
    <ng-container
      *ngFor="let action of input.actions"
      [dynamicWidget]="action"
      [context]="context"
    ></ng-container>
  </mat-action-row>
</mat-expansion-panel>
