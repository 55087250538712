import { CoalesceExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';

export const coalesceResolver =
  createNamedExpressionResolver<CoalesceExpression>(
    'coalesce',
    (parser, options, onDataChange, [values]) =>
      parser.parseWithCallback(
        values,
        {
          error: onDataChange.error,
          next: resolvedValues =>
            onDataChange.next(resolvedValues.find(Boolean)),
        },
        options
      )
  );
