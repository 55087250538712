import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  MatLegacyTabGroup as MatTabGroup,
  MatLegacyTabsModule,
} from '@angular/material/legacy-tabs';
import { Register } from '@app/utils/type-registry';
import {
  LocalActionModel,
  MatColorDefinitionModel,
  TabModel,
  TabsInput,
  TabsOutput,
} from '@trackback/widgets';
import { of } from 'rxjs';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { AsyncPipe, NgFor } from '@angular/common';
import { WidgetsState } from '@app/state/widgets/widgets.state';

@Register('Tabs')
@Component({
  selector: 'tb-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyTabsModule,
    NgFor,
    DynamicWidgetDirective,
    AsyncPipe,
    ParsePipe,
  ],
})
export class TabsComponent
  extends BaseWidgetComponent<TabsInput, TabsOutput>
  implements OnInit, AfterViewInit
{
  @ViewChild(MatTabGroup) _tabGroup: MatTabGroup;

  _tabs: TabModel[] = [];
  color: ThemePalette | null;
  backgroundColor: ThemePalette | null;

  protected setColorActive(
    type: 'foreground' | 'background',
    color: MatColorDefinitionModel
  ) {
    if (type === 'foreground') {
      this.color = color.palette || null;
    } else {
      this.backgroundColor = color.palette || null;
    }
    this._cd.markForCheck();
  }

  handleSetActiveIndexAction(action: LocalActionModel) {
    this._tabGroup.selectedIndex = action.payload as number;
  }

  handleAddTabAction(action: LocalActionModel) {
    if (action.payload) {
      const selectionBehaviour = (action.payload as Record<string, unknown>)
        .selection as string | undefined;
      const tab = (action.payload as Record<string, unknown>).tab as TabModel;
      const payloadIndex = (action.payload as Record<string, unknown>).index;
      const index =
        typeof payloadIndex === 'number' ? payloadIndex : this._tabs.length;
      const tabs = this._tabs;
      tabs.splice(index, 0, tab);
      this._tabs = tabs;
      switch (selectionBehaviour) {
        case 'new':
          this._tabGroup.selectedIndex = index;
          break;
        case 'old':
        case 'prev':
        case 'previous':
          // DO NOTHING
          break;
        default:
          this._tabGroup.selectedIndex = 0;
      }
      this._cd.markForCheck();
    }

    return of(null);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._tabs = [...this.input.tabs];
    switch (this.input.tabsAlignment) {
      case 'start':
        this.addStyleClasses('align-start');
        break;
      case 'center':
        this.addStyleClasses('align-center');
        break;
      case 'end':
        this.addStyleClasses('align-end');
        break;
      case 'stretch':
        this.addStyleClasses('align-stretch');
        break;
    }
  }

  ngAfterViewInit(): void {
    const currentOutput = (this._store.selectSnapshot(
      WidgetsState.getWidgetOutput(this.input.id)
    ) ?? {}) as Partial<TabsOutput>;

    this._tabGroup.selectedIndex = currentOutput.index;
  }
}
