<mat-chip-list  [style.paddingLeft]="paddingLeft"
           [style.paddingRight]="paddingRight"
           [style.paddingTop]="paddingTop"
           [style.paddingBottom]="paddingBottom"
           [style.marginTop]="marginTop"
           [style.marginLeft]="marginLeft"
           [style.marginBottom]="marginBottom"
           [style.marginRight]="marginRight"
           [ngClass]="classes"
>
  <ng-container *ngFor="let widget of input.children" [dynamicWidget]="widget" [context]="context" />
</mat-chip-list>

