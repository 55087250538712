<mat-tab-group
  [color]="color"
  [backgroundColor]="backgroundColor"
  (selectedIndexChange)="updateOutput({ index: $event })"
>
  <mat-tab
    *ngFor="let tab of _tabs"
    [label]="tab.label | parse: context | async"
  >
    <ng-container
      [dynamicWidget]="tab.widget"
      [context]="context"
    ></ng-container>
  </mat-tab>
</mat-tab-group>
