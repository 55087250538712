import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  WidgetsState,
  WidgetsStateModel,
} from '@app/state/widgets/widgets.state';
import { RouterStateModel } from '@ngxs/router-plugin';
import { DeepRequired } from 'ts-essentials';
import { TranslationState } from '@app/state/translations/translation.state';
import { APP_CONFIG, AppConfigModel } from '@app/models/app-config.model';
import { LayoutStateModel } from '@app/state/layout/layout.state';
import { createCallbackResolver, Parser } from '@app/expressions/parser';
import { createAliasResolver } from '@app/expressions/functions/aliases.resolver';
import { andResolver } from '@app/expressions/functions/and.resolver';
import { arrayIndexResolver } from '@app/expressions/functions/array-index.resolver';
import { coalesceResolver } from '@app/expressions/functions/coalesce.resolver';
import { changeCaseResolver } from '@app/expressions/functions/changecase.resolver';
import { concatResolver } from '@app/expressions/functions/concat.resolver';
import { contextResolver } from '@app/expressions/functions/context.resolver';
import { divideResolver } from '@app/expressions/functions/divide.resolver';
import { encodeURIResolver } from '@app/expressions/functions/encodeURI.resolver';
import { equalResolver } from '@app/expressions/functions/equal.resolver';
import { filterResolver } from '@app/expressions/functions/filter.resolver';
import { createFormatDateResolver } from '@app/expressions/functions/format-date.resolver';
import { distinctUntilChangedResolver } from '@app/expressions/functions/distinct-until-changed.function';
import { getResolver } from '@app/expressions/functions/get.resolver';
import { createGlobalResolver } from '@app/expressions/functions/global.resolver';
import { createIdsResolver } from '@app/expressions/functions/ids.resolver';
import { ifResolver } from '@app/expressions/functions/if.resolver';
import { includesResolver } from '@app/expressions/functions/includes.resolver';
import { invertResolver } from '@app/expressions/functions/invert.resolver';
import { createLocalWidgetIdResolver } from '@app/expressions/functions/local-widget-id.resolver';
import mapResolver from '@app/expressions/functions/map.resolver';
import { modResolver } from '@app/expressions/functions/mod.resolver';
import { multiplyResolver } from '@app/expressions/functions/multiply.resolver';
import { notResolver } from '@app/expressions/functions/not.resolver';
import { createOutputResolver } from '@app/expressions/functions/outputs.resolver';
import { regexResolver } from '@app/expressions/functions/regex.resolver';
import { createRemoteResolver } from '@app/expressions/functions/remote.resolver';
import { replaceResolver } from '@app/expressions/functions/replace.resolver';
import { roundResolver } from '@app/expressions/functions/round.resolver';
import { searchResolver } from '@app/expressions/functions/search.resolver';
import { searchableResolver } from '@app/expressions/functions/searchable.resolver';
import { sizeResolver } from '@app/expressions/functions/size.resolver';
import { subtractResolver } from '@app/expressions/functions/subtract.resolver';
import { sumResolver } from '@app/expressions/functions/sum.resolver';
import { lessThanResolver } from '@app/expressions/functions/less-than.resolver';
import { literalResolver } from '@app/expressions/functions/literal.resolver';
import { sortResolver } from '@app/expressions/functions/sort.resolver';
import { switchResolver } from '@app/expressions/functions/switch.resolver';
import { createFormatDateRelativeResolver } from '@app/expressions/functions/format-date-relative.resolver';
import { createTranslationResolver } from '@app/expressions/functions/translations.resolver';
import { valuesResolver } from '@app/expressions/functions/values.resolver';
import { minResolver } from '@app/expressions/functions/min.resolver';
import { floorResolver } from '@app/expressions/functions/floor.resolver';
import { toHoursMinsResolver } from '@app/expressions/functions/seconds-to-hours-mins.resolver';
import { debounceResolver } from '@app/expressions/functions/debounce.resolver';

/**
 * Service that provides helper functions to asynchronously replace binding expressions with valuesOf.
 */
@Injectable({
  providedIn: 'root',
})
export class ParserService {
  private _parser = new Parser();

  constructor(
    private readonly injector: Injector,
    private readonly store: Store,
    @Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel
  ) {
    this._parser.registerResolver(
      createAliasResolver(this.store.select(WidgetsState.getAliases))
    );
    this._parser.registerResolver(andResolver);
    this._parser.registerResolver(arrayIndexResolver);
    this._parser.registerResolver(coalesceResolver);
    this._parser.registerResolver(changeCaseResolver);
    this._parser.registerResolver(concatResolver);
    this._parser.registerResolver(contextResolver);
    this._parser.registerResolver(divideResolver);
    this._parser.registerResolver(encodeURIResolver);
    this._parser.registerResolver(equalResolver);
    this._parser.registerResolver(filterResolver);
    this._parser.registerResolver(
      createFormatDateResolver(
        this.store.select(TranslationState.getLanguageCode)
      )
    );
    this._parser.registerResolver(distinctUntilChangedResolver);
    this._parser.registerResolver(getResolver);
    this._parser.registerResolver(
      createGlobalResolver(
        this.store.select(
          (state: {
            widgets: WidgetsStateModel;
            router: DeepRequired<RouterStateModel>;
            layout: DeepRequired<LayoutStateModel>;
          }) => state
        )
      )
    );
    this._parser.registerResolver(
      createIdsResolver(this.store.select(WidgetsState.getWidgetIds))
    );
    this._parser.registerResolver(ifResolver);
    this._parser.registerResolver(includesResolver);
    this._parser.registerResolver(invertResolver);
    this._parser.registerResolver(
      createLocalWidgetIdResolver(
        options => (options.context || {})._id as string
      )
    );
    this._parser.registerResolver('map', mapResolver);
    this._parser.registerResolver(modResolver);
    this._parser.registerResolver(multiplyResolver);
    this._parser.registerResolver(notResolver);
    this._parser.registerResolver(
      createOutputResolver(this.store.select(WidgetsState.getAliasedOutputs))
    );
    this._parser.registerResolver(regexResolver);
    const http = this.injector.get(HttpClient);
    this._parser.registerResolver(createRemoteResolver(http.get.bind(http)));
    this._parser.registerResolver(replaceResolver);
    this._parser.registerResolver(roundResolver);
    this._parser.registerResolver(searchResolver);
    this._parser.registerResolver(searchableResolver);
    this._parser.registerResolver(sizeResolver);
    this._parser.registerResolver(subtractResolver);
    this._parser.registerResolver(sumResolver);
    this._parser.registerResolver(lessThanResolver);
    this._parser.registerResolver(literalResolver);
    this._parser.registerResolver(sortResolver);
    this._parser.registerResolver(switchResolver);
    this._parser.registerResolver(
      createFormatDateRelativeResolver(
        this.store.select(TranslationState.getLanguageCode)
      )
    );
    this._parser.registerResolver(
      createTranslationResolver(
        this.store.select(TranslationState.getCurrentTranslations)
      )
    );
    this._parser.registerResolver(valuesResolver);
    this._parser.registerResolver(minResolver);
    this._parser.registerResolver(floorResolver);
    this._parser.registerResolver(toHoursMinsResolver);
    this._parser.registerResolver(debounceResolver);
    this._parser.registerResolver(
      createCallbackResolver(object => {
        if (!this.config || !this.config.PRODUCTION) {
          return JSON.stringify(object);
        } else {
          return object;
        }
      }, 'stringify')
    );
  }

  public parse = this._parser.parse.bind(this._parser) as Parser['parse'];
  public parseOnce = this._parser.parseOnce.bind(
    this._parser
  ) as Parser['parseOnce'];
  public parseAsPromise = this._parser.parseAsPromise.bind(this._parser) as Parser['parseAsPromise'];
}
