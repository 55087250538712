<ng-container
  *ngIf="isLoadingResults"
  [dynamicWidget]="input.loadingWidget"
  [context]="context"
></ng-container>

<ng-container *ngIf="isLoadingResults !== true">
  <ng-container
    *ngIf="
      mergeContexts(
        context,
        wrapContext(
          filteredData | async,
          input?.filteredDataAlias || 'filteredData'
        )
      ) as mergedContext
    "
    [dynamicWidget]="input.widget"
    [context]="mergedContext"
  ></ng-container>
</ng-container>

<div
  class="paginator-container"
  [style.paddingLeft]="paddingLeft"
  [style.paddingRight]="paddingRight"
  [style.paddingTop]="paddingTop"
  [style.paddingBottom]="paddingBottom"
  [style.marginTop]="marginTop"
  [style.marginLeft]="marginLeft"
  [style.marginRight]="marginRight"
  [style.marginBottom]="marginBottom"
>
  <mat-spinner
    *ngIf="loadingStateCounter.isLoading$ | async"
    diameter="24"
  ></mat-spinner>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons="true"
    (page)="bindPaginator($event)"
  ></mat-paginator>
</div>
